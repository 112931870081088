<template>
  <div>
    <!-- Start Header Area -->
    <Header>
        <img slot="logo" src="../../assets/images/logo/logo-symbol-light.png"/>
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
      v-bind:style='{ backgroundImage: "url(" + photo + ")" }'
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="blog-single-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient">
                {{ title }}
              </h2>
              <p class="white--text">
                  "{{ quote }}"
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--110 pb--70 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner" v-html="content">
                {{ content }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import axios from 'axios';

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        title: null,
        content: null,
        photo: null,
        quote: null,
      };
    },
    unmounted() {
        this.title = null;
    },
    mounted() {
        const slug = this.$route.path.replace(/\\|\//g,'') ;
        console.log("SLUG", this.$route.path, slug);

        axios({
            url: process.env.VUE_APP_API_ENDPOINT,
            method: 'post',
            data: {
                query: `
                    query getPage {
                        page(slug: "` + slug + `") {
                            title,
                            photo,
                            body
                        },
                        randomQuote {
                            body
                        }
                    }
                `
            }
        })
        .then(response => {
            this.title = response.data.data.page.title;
            this.content = response.data.data.page.body;
            this.photo = response.data.data.page.photo;
            this.quote = response.data.data.randomQuote.body;
        })
    }
  };
</script>
